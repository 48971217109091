.sidenav {
    width: 250px;
    transition: width 0.3s ease-in-out;
    height: 100vh;
    background-color: rgb(10,25,41);
    padding-top: 28px;
}
.sidenavClosed {
    composes: sidenav;
    transition: width 0.3s ease-in-out;
    width: 60px
}
.sideitem {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    cursor: pointer;
    color: #B2BAC2;
    text-decoration: none;
    overflow: hidden;
}
.linkText {
    padding-left: 16px;
}

.sideitem:hover {
    background-color: #244f7d1c;

}
.menuBtn {
    align-self: center;
    align-self: flex-start;  
    justify-self: flex-end;
    color: #B2BAC2;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding-left: 20px;
}

.menuModeBtn {
    align-self: center;
    align-self: flex-start;  
    justify-self: flex-end;
    color: #B2BAC2;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px 20px;
}

/*.dark {
    background-image: url('/public/raiden1.png');
    background-color: black;
    background-repeat: no-repeat;
    background-size: 100%;
    color: lime;      
  }


.light {
    background-image: url('/public/kazuha1.jpg');
    background-color: white;
    background-repeat: no-repeat;
    background-size: 100%;
    color: black;
}*/
