body {
  margin: 0;
  padding: 0;
}

.dark {
  background-image: url('/public/raiden1.png');
  background-color: black;
  background-repeat: no-repeat;
  background-size: 100%;  
  color: lime;      
}

.light {
  background-image: url('/public/kazuha1.jpg');
  background-color: white;
  background-repeat: no-repeat;
  background-size: 100%;  
  color: white;
}

.App {
  display: flex;
}
main {
  padding: 10px;
}

/*@media (min-width:769px){
	body {
	  background-image: url('/public/atago1.jpg');
	  background-repeat: no-repeat;
	  background-size: 100%;
	}
}

@media (max-width:768px){
  body {
    background-image: url('/public/akagi1.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
  }
}*/